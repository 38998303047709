import { addMinutes, format } from 'date-fns';

const timeFormat = 'HH:mm';

export const mergeUniques = (a, b, prop) => {
  const reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]));
  return reduced.concat(b);
};

// TODO: make the top one the same as this one once it's been tested
// as we should be getting everything from a, not b, b is the secondary array
export const mergeUniquesForGraph = (a, b, prop) => {
  const reduced = b.filter(aitem => !a.find(bitem => aitem[prop] === bitem[prop]));
  return a.concat(reduced);
};

export const formatDailyUniquesData = (locationsFlat, btleUniquesData) => {
  let maxUniques = 0;
  const hourData = [];
  const minuteData = [];
  locationsFlat.forEach(location => {
    const locationData = btleUniquesData[location.location.id];
    if (!locationData) return;
    const hoursArray = Object.values(
      locationData.reduce((hours, minute) => {
        const hour = Math.floor(minute.timestamp / 3600000) * 3600000;
        return {
          ...hours,
          [hour]: {
            hour,
            count: (hours[hour]?.count || 0) + minute.count,
            minutes: [...(hours[hour]?.minutes || []), [minute.timestamp, minute.count]] // [hour].minutes
          } // [hour]
        }; // return
      }, {})
    ); // hoursArray = Object.values().sort()
    // now let's backfill the hoursArray with empty minutes
    for (let i = 0; i < hoursArray.length; i++) {
      const nextHour = new Date(hoursArray[i + 1]?.hour);
      if (nextHour) {
        const currentMinute = new Date(hoursArray[i].hour);
        const nextMinute = addMinutes(nextHour, -1);
        const currentMinuteTime = currentMinute.getTime();
        const nextMinuteTime = nextMinute.getTime();
        const minutes = [];
        for (let j = currentMinuteTime; j <= nextMinuteTime; j += 60000) {
          minutes.push([j, 0]);
        } // for
        const mergedMinutes = mergeUniquesForGraph(hoursArray[i].minutes, minutes, 0).sort(
          (a, b) => a[0] - b[0]
        );
        hoursArray[i].minutes = mergedMinutes;
      } // if
    } // for
    maxUniques = Math.max(
      maxUniques,
      hoursArray.length
        ? // not sure why multiplying by 1.1
          hoursArray.sort((a, b) => b.count - a.count)[0].count * 1.1 ||
            location.location.capacity ||
            100
        : location.location.capacity || 100
    );
    hourData.push({
      name: `${location.type}: ${location.location.name}`,
      id: location.location.id,
      color:
        location.location?.graphColor?.css?.backgroundColor ||
        location.location?.graphColor ||
        null,
      data: hoursArray
        .sort((a, b) => a.hour - b.hour)
        .map(hour => {
          const currentHour = new Date(hour.hour);
          const fromHour = format(currentHour, timeFormat);
          const toHour = format(addMinutes(currentHour, 59), timeFormat);
          return {
            name: `${fromHour} to ${toHour}`,
            y: hour.count,
            drilldown: `${location.location.id}-${hour.hour}`
          };
        })
    }); //  hourData.push({
    hoursArray.forEach(hour => {
      const currentHour = new Date(hour.hour);
      const fromHour = format(currentHour, timeFormat);
      const toHour = format(addMinutes(currentHour, 59), timeFormat);
      minuteData.push({
        name: `${location.location.name} ${fromHour} to ${toHour}`,
        id: `${location.location.id}-${hour.hour}`,
        data: hour.minutes.map(([timestamp, count]) => [
          format(new Date(timestamp), timeFormat),
          count
        ])
      }); // minuteData.push()
    }); // hoursArray.forEach()
  });

  return { maxUniques, hourData, minuteData };
};
